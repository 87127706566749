.headerTop {
  background-color: 'primary';
  /* height: 50px; */
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.title {
  font-size: 30px;
  padding: 10px;
  align-items: center;
}

.title_history {
  font-size: 20px;
  font-weight: 5 00;
  padding: 10px;
  align-items: center;
}

.content {
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(181, 181, 181, 1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(181, 181, 181, 1);
  box-shadow: 0px 3px 6px 0px rgba(181, 181, 181, 1);
}

.content__history {
  border: 1px solid #F0F0F0;
  border-radius: 4px;

}

.buttonExchange {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 50%;
  padding: 8px 8px;
  line-height: 8px;
}

.buttonExchange:hover {
  background-color: #eee;
  border-radius: 50%;
  padding: 8px 8px;
  line-height: 8px;
}

.buttonExchange:active {
  background-color: #ccc;
}

.boxTranslate {}

.btn:focus {
  outline: none;
}

.from_language {
  resize: none;
  border: none;
  float: left;
  width: 100%;
  /* min-height: 100px; */
  padding: 0 10px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  font-size: 24px;
}

.to_language {
  resize: none;
  border-width: none;
  border: none;
  float: left;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.buttonUtility {
  background-color: #fff;
  color: 'primary';
  border: none;
  font-weight: 500;
  border-radius: 4px;
  padding: 8px 8px;
  line-height: 8px;
}

.buttonUtility:hover {
  background-color: rgb(248, 248, 248);
  border-radius: 4px;
  padding: 8px 8px;
  line-height: 8px;
}

.buttonUtility:active {
  background-color: #ccc;
}

.resultTranslate_bandich {
  font-size: 30px;
  color: rgb(167, 167, 167);
}

.resultTranslate_dadich {
  font-size: 24px;
  word-wrap: break-word;
}