@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification-wrapper {
	position: fixed;
	/* top: 10px; */
	bottom: 10px;
	right: 10px;
	width: 250px;
}

.notification-item {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
	border-radius: 10px;
	background-color: white;
	overflow: hidden;
	margin-bottom: 20px;
	/* animation: Slideleft 0.5s; */
	/* animation-fill-mode: forwards; */
}

/* @keyframes Slideleft {
	0% {
		margin-left: 100%;
	}

	100% {
		margin-left: 0;
	}
} */

@keyframes SlideRight {
	0% {
		margin-left: 0;
	}

	100% {
		margin-left: 120%;
	}
}

.notification-item.notification-exit {
	animation: SlideRight 0.5s;
	animation-fill-mode: forwards;
}


.notification-item p {
	margin: 0;
	/* padding: 5px 0; */
}
